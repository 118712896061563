/* eslint-disable array-callback-return */
import {
  useBoolean,
  useToastr,
} from '@laxmimanogna/code-quick-components';
import { createContext, useState } from 'react';
import { successToast } from '../components/successToast';
import { ROLES_VALUES } from '../constants/constants';
import { REBUTTAL_STATUS } from '../pages/audit_sheet/constants';
import auditSheetRepository from '../repositories/AuditSheetRepository';
import { toTitleCase } from '../utils/common.utils';
import { createEmptyTablesProps } from '../pages/audit_sheet/utils/commonAuditSheet.utils';

export const AuditSheetContext = createContext({
  isAuditSheetLoading: false,
  createAuditSheet: async () => {},
  getAuditSheet: async () => {},
  monitorAuditHour: async () => {},
  currentUpload: {},
  getAuditUpload: async () => {},
  updateAuditSheet: async () => {},
  getIndustryCodes: async () => {},
  industryCodes: {},
  recentAuditImageUpload: async () => {},
  updateActivity: async () => {},
  getUsers: async () => {},
  getAllUsers: async () => {},
  getComments: async () => {},
  comments: [],
  setComments: async () => {},
  users: [],
  allUsers: [],
  isUpdateActivityLoading: false,
  isUserLoading: false,
  isCommentLoading: false,
  allComments: [],
  setAllComments: () => {},
  getAllComments: async () => {},
  commentFilters: {},
  setCommentFilters: () => {},
  getCommentFlag: async () => {},
  commentFlags: [],
  setCommentFlags: () => {},
  selectedCell: {},
  setSelectedCell: () => {},
  deleteComment: async () => {},
  updateComment: async () => {},
  postComment: async () => {},
  isPostCommentLoading: false,
  isAllCommentLoading: false,
  totalComments: 0,
  providerOptions: [],
  fetchProviderDropdown: async () => {},
});
const AuditSheetProvider = props => {
  const toast = useToastr();

  const [isAuditSheetLoading, iasState] = useBoolean(false);
  const [currentUpload, setCurrentUpload] = useState({});
  const [industryCodes, setIndustryCode] = useState({});
  const [users, setUsers] = useState([]);
  const [isUpdateActivityLoading, iuaState] = useBoolean(false);
  const [isCommentLoading, icState] = useBoolean(false);
  const [isUserLoading, iuState] = useBoolean(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isPostCommentLoading, ipclState] = useBoolean(false);
  const [comments, setComments] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [isAllCommentLoading, iaclState] = useBoolean(false);

  const [commentFlags, setCommentFlags] = useState([]);

  const [selectedCell, setSelectedCell] = useState({});
  const [commentFilters, setCommentFilters] = useState({});
  const [totalComments, setTotalComments] = useState(0);
  const [providerOptions, setProviderOptions] = useState([]);

  async function createAuditSheet(payload, uploadId) {
    try {
      const response = await auditSheetRepository.createAuditSheet(
        payload,
        uploadId
      );
      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    }
  }
  async function updateAuditSheet(payload, uploadId) {
    try {
      iasState.on();
      const response = await auditSheetRepository.updateAuditSheet(
        payload,
        uploadId
      );
      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    } finally {
      iasState.off();
    }
  }

  async function getAuditSheet(uploadId, currentUpload) {
    try {
      const response = await auditSheetRepository.getAuditSheet(uploadId);

      if (!response.length) {
        return createEmptyTablesProps(currentUpload, currentUpload);
      }

      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    }
  }

  async function getAuditUpload(uploadId) {
    try {
      const response = await auditSheetRepository.getAuditUpload(uploadId);
      if (Object.keys(response).length) {
        setCurrentUpload(response);
      }
      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    }
  }

  async function monitorAuditHour(uploadId) {
    try {
      await auditSheetRepository.auditHourMonitor(uploadId);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    }
  }

  async function recentAuditImageUpload(uploadId, data) {
    try {
      await auditSheetRepository.recentAuditImageUpload(uploadId, data);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error" : err.cause.detail};
    }
  }

  async function getIndustryCodes(uploadId) {
    try {
      const response = await auditSheetRepository.getIndustryCodes();
      setIndustryCode(response);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    }
  }

  async function updateActivity(data) {
    try {
      iuaState.on();
      const response = await auditSheetRepository.updateActivity(data);
      if (data.length) {
        data[0].row.map(rowId => {
          if (commentFlags[rowId]) {
            commentFlags[rowId] = [...commentFlags[rowId], ...data[0].column];
          } else {
            commentFlags[rowId] = [...data[0].column];
          }
        });
        setCommentFlags({ ...commentFlags });
      }
      const toastProps = {
        header: 'Success!',
        description: `${
          data[0].action === REBUTTAL_STATUS
            ? 'Marked as rebuttal.'
            : !data[0].parent
            ? 'Comment sent'
            : 'Reply sent'
        }`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    } finally {
      iuaState.off();
    }
  }

  async function getUsers() {
    try {
      iuState.on();
      const response = await auditSheetRepository.getUsers();
      const userData = response.map(r => {
        return {
          id: r.id,
          display: `${r.first_name} ${r.last_name} | ${ROLES_VALUES[r.role]}`,
          first_name: r.first_name,
          last_name: r.last_name,
        };
      });
      setUsers([...userData]);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    } finally {
      iuState.off();
    }
  }

  async function getAllUsers() {
    try {
      iuState.on();
      const response = await auditSheetRepository.getAllUsers();
      const userData = response.map(r => {
        return {
          id: r.id,
          first_name: r.first_name,
          last_name: r.last_name,
        };
      });
      setAllUsers([...userData]);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    } finally {
      iuState.off();
    }
  }

  async function getComments(uploadId, queryParams) {
    try {
      icState.on();
      const response = await auditSheetRepository.getComments(
        uploadId,
        queryParams
      );
      // return response
      setComments(response.results);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    } finally {
      icState.off();
    }
  }

  async function getAllComments() {
    try {
      iaclState.on();
      const response = await auditSheetRepository.getComments(
        commentFilters.id,
        commentFilters
      );
      if (response.results) {
        setTotalComments(response.count);
        if (commentFilters.page === 1) {
          setAllComments(response.results);
        }
        return response.results;
      }
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail}
    } finally {
      iaclState.off();
    }
  }

  async function getCommentFlag(uploadId) {
    try {
      const response = await auditSheetRepository.getCommentFlag(uploadId);
      const obj = {};
      if (response.length) {
        for (let i = 0; i < response.length; i++) {
          const comment = response[i];
          if (obj[comment[0]]) {
            obj[comment[0]] = [...obj[comment[0][0]], comment[1][0]];
          } else {
            obj[comment[0]] = [comment[1][0]];
          }
        }
      }
      setCommentFlags(obj);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    }
  }

  async function deleteComment(commentId, commentParentId, notes) {
    try {
      await auditSheetRepository.deleteComment(commentId);
      if (commentParentId) {
        const parentIndex = allComments.findIndex(
          c => c.id === commentParentId
        );
        allComments[parentIndex]['reply_comments'] = allComments[parentIndex][
          'reply_comments'
        ].filter(c => c.id !== commentId);
        setAllComments(allComments);
      } else {
        setAllComments(allComments.filter(c => c.id !== commentId));
      }
      const toastProps = {
        header: 'Success!',
        description: `${notes ? 'Note' : 'Comment'} is deleted`,
      };
      successToast(toast, toastProps);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    }
  }

  async function updateComment(commentId, payload, commentParentId) {
    try {
      payload.action = 'UPDATE';
      await auditSheetRepository.editComment(commentId, payload);
      if (commentParentId) {
        const parentIndex = allComments.findIndex(
          c => c.id === commentParentId
        );
        const index = allComments[parentIndex].reply_comments.findIndex(
          c => c.id === commentId
        );
        allComments[parentIndex]['reply_comments'][index]['comment'] =
          payload.comment;
        setAllComments(allComments);
      } else {
        const index = allComments.findIndex(c => c.id === commentId);
        allComments[index]['comment'] = payload.comment;
        setAllComments(allComments);
      }
      const toastProps = {
        header: 'Success!',
        description: `${payload.notes ? 'Note' : 'Comment'} is updated`,
      };
      successToast(toast, toastProps);
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    }
  }

  async function postComment(data) {
    try {
      ipclState.on();
      if (!data.tagged_user) {
        data.tagged_user = [];
      }
      const response = await auditSheetRepository.postComment(data);
      return response;
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
    } finally {
      ipclState.off();
    }
  }

  async function fetchProviderDropdown(uploadId) {
    try {
      const response = await auditSheetRepository.getProviderDropdown(uploadId);
      setProviderOptions(
        response.map(p => ({
          id: p.provider_id,
          value: toTitleCase(`${p.first_name} ${p.last_name}`),
        }))
      );
    } catch (err) {
      toast.showError({
        description: err.toString(),
      });
      return {"error": err.cause.detail};
    }
  }

  const mContext = {
    isAuditSheetLoading,
    createAuditSheet,
    getAuditSheet,
    monitorAuditHour,
    currentUpload,
    getAuditUpload,
    updateAuditSheet,
    getIndustryCodes,
    industryCodes,
    recentAuditImageUpload,
    updateActivity,
    getUsers,
    getAllUsers,
    getComments,
    comments,
    setComments,
    users,
    allUsers,
    isUpdateActivityLoading,
    isUserLoading,
    isCommentLoading,
    allComments,
    setAllComments,
    getAllComments,
    commentFilters,
    setCommentFilters,
    getCommentFlag,
    commentFlags,
    setCommentFlags,
    selectedCell,
    setSelectedCell,
    deleteComment,
    updateComment,
    postComment,
    isPostCommentLoading,
    isAllCommentLoading,
    totalComments,
    providerOptions,
    fetchProviderDropdown,
  };

  return (
    <AuditSheetContext.Provider value={mContext}>
      {props.children}
    </AuditSheetContext.Provider>
  );
};

export default AuditSheetProvider;
