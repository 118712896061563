/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Spinner, Text, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import ROUTES from '../../../constants/Routes';

export const AccessDenied = ({errMsg}) => {
    const navigate = useNavigate();
    const goToDashboard = () => {
        navigate(ROUTES.DASHBOARD); // Replace with your actual dashboard path
      };

    return (
        <Box 
            height="100vh" 
            display="flex"
            flexDirection="column"
            justifyContent="center" 
            alignItems="center" 
            bg="red.100" 
            color="red.800"
            textAlign="center"
            p={4}
        >
            <Text fontSize="2xl">{errMsg || "You do not have permission to access this resource."}</Text>
            <Button 
                colorScheme="blue" 
                variant="solid" 
                onClick={goToDashboard}
            >
                Dashboard
            </Button>
        </Box>
    );
        
    
};



